import styles from '../../scss/variables.scss';

export type ITrafficLightThresholdColors = {
  0: string;
  1: string;
  2: string;
  empty: string;
};

const calculatelLampFromAllSideEffects = (
  alarmThresholdReview: any
): string => {
  const trafficLightThresholdColors: any | any = {
    0: styles.yellowGreen,
    1: styles.yellowUpdatedProgress,
    2: styles.rose,
    empty: ''
  };

  // 0, 1, 2, 3 or 'empty
  let key: any = 'empty';

  alarmThresholdReview.map((item: any, index: number) => {
    const tempMaxKey =
      item.values.length > 0 ? Math.max(...item?.values) : 'empty';
    const maxThreshold = item?.alarmThreshold?.[0]?.max;

    // red
    if (
      (key === 'empty' || key !== 2) &&
      tempMaxKey >= maxThreshold &&
      tempMaxKey > 0 &&
      tempMaxKey !== -1
    ) {
      key = 2;
    }

    // yellow
    if (
      key !== 2 &&
      tempMaxKey <= maxThreshold &&
      tempMaxKey > 0 &&
      tempMaxKey !== -1
    ) {
      key = 1;
    }

    // green
    if (
      key !== 2 &&
      key !== 1 &&
      tempMaxKey <= maxThreshold &&
      tempMaxKey !== -1
    ) {
      key = 0;
    }
  });
  return trafficLightThresholdColors[key];
};

export default calculatelLampFromAllSideEffects;
